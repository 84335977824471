<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pl-2 pr-4 mx-0">
      <section class="d-flex justify-space-between align-center border">
        <div class="d-flex align-center">
          <v-icon class="section-handle">
            {{ mdiDragVertical }}
          </v-icon>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Workflow Name"
            rules="required"
          >
            <v-text-field
              label="Workflow Name"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              class="py-0 mt-0"
              v-model="workflowSettingBeingEdited.name"
              @click.stop
              @keyup.space.prevent
              hide-details
              @input="onChange"
              :disabled="!canManage811Codes"
            />
          </validation-provider>
        </div>

        <div class="d-flex align-center">
          <v-switch
            label="Enabled"
            hide-details
            class="py-0 my-0"
            v-model="workflowSettingBeingEdited.enabled"
            @click.stop
            @change="onChange"
            :disabled="!canManage811Codes"
          >
          </v-switch>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="!canManage811Codes"
              >
                <v-icon>
                  {{ mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0 ma-0">
              <v-list-item class="pa-0 ma-0" @click="$emit('delete-workflow')">
                <v-btn text color="primary" :disabled="!canManage811Codes">
                  <v-icon color="primary" class="mr-1">{{ mdiDelete }}</v-icon>
                  Delete
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </section>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="px-2">
      <section style="width: 33%" class="pb-2">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Workflow Trigger"
          rules="required"
        >
          <v-select
            autocomplete="off"
            v-model="workflowSettingBeingEdited.trigger"
            label="Workflow Trigger"
            color="primary"
            :error-messages="errors"
            :success="valid"
            hide-details
            item-text="label"
            item-value="value"
            :items="TRIGGER_CHOICES"
            :disabled="!canManage811Codes"
            :full-width="false"
            class="py-0 my-0"
            @input="onChange"
          ></v-select>
        </validation-provider>
      </section>

      <div class="pb-3 font-weight-medium">
        <span
          v-if="workflowSettingBeingEdited.trigger === TRIGGER.TICKET_RECEIVED"
          >When a ticket is received that matches the following
          conditions:</span
        >
        <span
          v-else-if="
            workflowSettingBeingEdited.trigger === TRIGGER.TICKET_ASSIGNED
          "
          >When a ticket is assigned that matches the following
          conditions:</span
        >
      </div>

      <section class="d-flex flex-wrap gap pb-3">
        <div>
          <div class="caption">Priorities</div>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Ticket Priority"
            rules="checkboxRequired"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                  {{ getPrioritiesChoices() }}
                  <v-avatar>
                    <v-icon class="ml-3">
                      {{ mdiMenuDown }}
                    </v-icon>
                  </v-avatar>
                </v-chip>
              </template>
              <v-card class="pa-0 mx-0 overflow-y-auto" max-height="90vh">
                <v-card-text class="py-2 px-3">
                  <CheckboxGroup
                    :errors="errors"
                    :valid="valid"
                    @input="onChange"
                    v-model="ticketPrioritySettings"
                  />
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="caption red--text">{{ errors[0] }}</div>
          </validation-provider>
        </div>

        <div>
          <div class="caption">Ticket Types</div>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Ticket Type"
            rules="checkboxRequired"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                  {{ getTypesChoices() }}
                  <v-avatar>
                    <v-icon class="ml-3">
                      {{ mdiMenuDown }}
                    </v-icon>
                  </v-avatar>
                </v-chip>
              </template>
              <v-card class="pa-0 mx-0 overflow-y-auto" max-height="90vh">
                <v-card-text class="py-2 px-3">
                  <CheckboxGroup
                    :errors="errors"
                    :valid="valid"
                    @input="onChange"
                    v-model="ticketTypeSettings"
                  />
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="caption red--text">{{ errors[0] }}</div>
          </validation-provider>
        </div>

        <div>
          <div class="caption">Days</div>
          <validation-provider
            name="Days"
            rules="daysSettingsRequired"
            v-slot="{ errors }"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                  {{ getDaysChoices() }}
                  <v-avatar>
                    <v-icon class="ml-3" :disabled="!canManage811Codes">
                      {{ mdiMenuDown }}
                    </v-icon>
                  </v-avatar>
                </v-chip>
              </template>
              <v-card class="pa-0 mx-0">
                <v-card-text class="pa-2">
                  <DaysSelector v-model="daysSettings" @input="onChange" />
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="caption red--text">{{ errors[0] }}</div>
          </validation-provider>
        </div>

        <div>
          <div class="caption">Hours</div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                {{ getSelectHoursChoice() }}
                <v-avatar>
                  <v-icon class="ml-3">
                    {{ mdiMenuDown }}
                  </v-icon>
                </v-avatar>
              </v-chip>
            </template>

            <v-card class="pa-0 ma-0">
              <v-card-text class="pa-3 ma-0" @click.stop>
                <v-switch
                  v-model="workflowSettingBeingEdited.filter.hours.all_hours"
                  label="All Hours"
                  hide-details
                  class="pa-0 ma-0"
                  @click.stop
                  @change="onChange"
                  @input="onChange"
                  :disabled="!canManage811Codes"
                ></v-switch>

                <template
                  v-if="!workflowSettingBeingEdited.filter.hours.all_hours"
                >
                  <div class="caption font-weight-medium pt-2 black--text">
                    From
                  </div>
                  <div class="d-flex gap">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="From Hour"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="from.hour"
                        label="Hours"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="hoursChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="From Minute"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="from.minute"
                        label="Minutes"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="minutesChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="From Period"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="from.period"
                        label="Period"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="periodChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="From Time Zone"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="from.timeZone"
                        label="Time Zone"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="timeZoneChoices"
                        class="hour-drop-down"
                        @change="to.timeZone = from.timeZone"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>
                  </div>

                  <div class="caption font-weight-medium pt-2 black--text">
                    To
                  </div>
                  <div class="d-flex gap">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="To Hour"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="to.hour"
                        label="Hours"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="hoursChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="To Minute"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="to.minute"
                        label="Minutes"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="minutesChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="To Period"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="to.period"
                        label="Period"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="periodChoices"
                        class="hour-drop-down"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="To Time Zone"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="to.timeZone"
                        label="Time Zone"
                        color="primary"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="timeZoneChoices"
                        class="hour-drop-down"
                        @change="from.timeZone = to.timeZone"
                        :disabled="!canManage811Codes"
                        @input="onChange"
                      ></v-select>
                    </validation-provider>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>

        <div>
          <div class="caption">Assignment Area</div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                {{
                  getSelectedLocateRequestAccountRegionChoiceName(
                    workflowSettingBeingEdited.filter
                      .locate_request_account_region_id
                  )
                }}
                <v-avatar>
                  <v-icon class="ml-3">
                    {{ mdiMenuDown }}
                  </v-icon>
                </v-avatar>
              </v-chip>
            </template>

            <v-card class="pa-0 ma-0">
              <v-card-text class="pa-0 ma-0">
                <v-list dense class="pa-0 ma-0">
                  <v-list-item
                    @click="
                      workflowSettingBeingEdited.filter.locate_request_account_region_id =
                        null;
                      onChange();
                    "
                    class="pa-1 ma-0"
                  >
                    <v-list-item-content class="px-3 ma-0 body-1">
                      Not Specified
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="c of locateRequestAccountRegionChoices"
                    :key="c.value"
                    @click="
                      populateAssignedTo(c.value);
                      onChange();
                    "
                    class="pa-1 ma-0"
                  >
                    <v-list-item-content class="px-3 ma-0 body-1">
                      {{ c.label }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>

        <div
          v-if="workflowSettingBeingEdited.trigger === TRIGGER.TICKET_ASSIGNED"
        >
          <div class="caption">Assigned To</div>
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="showAssignedToMenu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" :disabled="!canManage811Codes">
                {{
                  getSelectedAssignedToUserNames(
                    workflowSettingBeingEdited.filter.assigned_to_user_ids
                  )
                }}
                <v-avatar>
                  <v-icon class="ml-3">
                    {{ mdiMenuDown }}
                  </v-icon>
                </v-avatar>
              </v-chip>
            </template>

            <v-card class="pa-0 ma-0">
              <v-card-text class="pa-0 ma-0">
                <v-list dense class="pa-0 ma-0">
                  <v-list-item>
                    <v-list-item-content>
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Assigned To"
                        class="py-1"
                        rules="required"
                      >
                        <v-autocomplete
                          multiple
                          label="Assigned To"
                          :error-messages="errors"
                          :success="valid"
                          :items="assignToUserChoices"
                          clearable
                          item-value="value"
                          item-text="label"
                          color="primary"
                          hide-details="auto"
                          class="pb-0 pt-2 my-0 drop-down"
                          v-model="
                            workflowSettingBeingEdited.filter
                              .assigned_to_user_ids
                          "
                          @change="onAssignedToUserIdsChange"
                          :disabled="!canManage811Codes"
                        />
                      </validation-provider>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </section>

      <validation-provider rules="oneActionRequired" v-slot="{ errors }">
        <div class="font-weight-medium pt-2">Do the following:</div>
        <section class="red--text">{{ errors[0] }}</section>
        <DoTheFollowingForm
          v-model="workflowSettingBeingEdited"
          :users="users"
          @input="onChange"
        />
      </validation-provider>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiMenuDown,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import DoTheFollowingForm from "@/components/accounts-811/edit-workflows-form/edit-workflow-form/DoTheFollowingForm";
import DaysSelector from "@/components/shared/DaysSelector";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import moment from "moment";
import permissionsMixin from "@/mixins/permissionsMixin";
import { TRIGGER } from "@/constants/workflow";
import { isObject } from "lodash";

const daysSettings = [
  { label: "sunday" },
  { label: "monday" },
  { label: "tuesday" },
  { label: "wednesday" },
  { label: "thursday" },
  { label: "friday" },
  { label: "saturday" },
];
const hoursChoices = Array(13)
  .fill()
  .map((_, i) => i)
  .slice(1)
  .map((h) => {
    return {
      label: h.toString().padStart(2, "0"),
      value: h.toString().padStart(2, "0"),
    };
  });
const minutesChoices = Array(60)
  .fill()
  .map((_, i) => i)
  .map((m) => {
    return {
      label: m.toString().padStart(2, "0"),
      value: m.toString().padStart(2, "0"),
    };
  });
const periodChoices = [
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" },
];
const timeZoneChoices = [
  { label: "ET", value: "ET" },
  { label: "CT", value: "CT" },
  { label: "MT", value: "MT" },
  { label: "PT", value: "PT" },
];
const TIME_ZONE_REGEX = /ET|CT|MT|PT/g;

const TRIGGER_CHOICES = [
  { label: "Ticket Assigned", value: TRIGGER.TICKET_ASSIGNED },
  { label: "Ticket Received", value: TRIGGER.TICKET_RECEIVED },
];

export default {
  name: "EditWorkflowForm",
  components: { DoTheFollowingForm, DaysSelector, CheckboxGroup },
  computed: {
    assignToUserChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }

      const usersChoices = this.users
        .filter((u) => u.is_active && !u.is_contact && u.locate_requests_user)
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
      return [...usersChoices];
    },
    locateRequestAccountRegionChoices() {
      if (!Array.isArray(this.locateRequestAccountRegions)) {
        return [];
      }
      return this.locateRequestAccountRegions.map((r) => {
        const { region_name: label, locate_request_account_region_id: value } =
          r;
        return { label, value };
      });
    },
    hoursFrom() {
      const { hour, minute, period, timeZone } = this.from;
      const periodValues = periodChoices.map((c) => c.value);
      const timeZoneValues = timeZoneChoices.map((c) => c.value);
      if (
        !isNaN(hour) &&
        !isNaN(minute) &&
        periodValues.includes(period) &&
        timeZoneValues.includes(timeZone)
      ) {
        return `${hour}:${minute} ${period} ${timeZone}`;
      }
      return undefined;
    },
    hoursTo() {
      const { hour, minute, period, timeZone } = this.to;
      const periodValues = periodChoices.map((c) => c.value);
      const timeZoneValues = timeZoneChoices.map((c) => c.value);
      if (
        !isNaN(hour) &&
        !isNaN(minute) &&
        periodValues.includes(period) &&
        timeZoneValues.includes(timeZone)
      ) {
        return `${hour}:${minute} ${period} ${timeZone}`;
      }
      return undefined;
    },
  },
  props: {
    value: Object,
    users: Array,
    ticketTypes: Array,
    ticketPriorities: Array,
    locateRequestAccountRegions: Array,
  },
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiMenuDown,
      workflowSettingBeingEdited: {},
      ticketTypeSettings: [],
      ticketPrioritySettings: [],
      daysSettings,
      hoursChoices,
      minutesChoices,
      periodChoices,
      timeZoneChoices,
      from: { hour: "01", minute: "00", period: "AM", timeZone: "MT" },
      to: { hour: "01", minute: "00", period: "AM", timeZone: "MT" },
      TRIGGER_CHOICES,
      TRIGGER,
      showAssignedToMenu: false,
    };
  },
  mixins: [permissionsMixin],
  async beforeMount() {
    this.workflowSettingBeingEdited = cloneDeep(this.value);
    await this.populateTicketTypeSettings(this.ticketTypes);
    await this.populateTicketPrioritySettings(this.ticketPriorities);
    this.populateDaysSettings();
    this.populateHoursSettings();
    this.populateWorkflowTrigger();
  },
  methods: {
    populateWorkflowTrigger() {
      if (!this.workflowSettingBeingEdited.trigger) {
        this.$set(
          this.workflowSettingBeingEdited,
          "trigger",
          TRIGGER.TICKET_RECEIVED
        );
        this.populateAssignedToFilter();
      }
    },
    populateAssignedToFilter() {
      if (
        typeof this.workflowSettingBeingEdited?.filter?.assigned_to_user_ids ===
        "undefined"
      ) {
        const allUsers = this.assignToUserChoices.map((c) => c.value);
        if (!isObject(this.workflowSettingBeingEdited.filter)) {
          this.workflowSettingBeingEdited.filter = {};
        }
        this.workflowSettingBeingEdited.filter.assigned_to_user_ids = [
          ...allUsers,
        ];
      }
    },
    populateAssignedTo(locateRequestAccountRegionId) {
      this.$set(
        this.workflowSettingBeingEdited.filter,
        "locate_request_account_region_id",
        locateRequestAccountRegionId
      );
      this.$set(
        this.workflowSettingBeingEdited.actions.assign_ticket,
        "enabled",
        false
      );
      const locateRequestAccountRegion = this.locateRequestAccountRegions.find(
        (r) =>
          r.locate_request_account_region_id === locateRequestAccountRegionId
      );
      this.workflowSettingBeingEdited.actions.assign_ticket.assign_to_user_id =
        locateRequestAccountRegion?.user_id;
    },
    onChange() {
      const workflowSetting = cloneDeep(this.workflowSettingBeingEdited);
      workflowSetting.filter.priorities = [
        ...this.ticketPrioritySettings
          .filter((s) => s.value)
          .map((s) => s.label),
      ];
      workflowSetting.filter.ticket_types = [
        ...this.ticketTypeSettings.filter((s) => s.value).map((s) => s.label),
      ];
      workflowSetting.filter.days = Object.fromEntries(
        this.daysSettings.map((s) => {
          const { label, value } = s;
          return [label, value];
        })
      );
      const { hoursFrom, hoursTo } = this;
      workflowSetting.filter.hours = {
        ...workflowSetting.filter.hours,
        from: hoursFrom,
        to: hoursTo,
      };
      this.showAssignedToMenu = false;
      this.$emit("input", workflowSetting);
    },
    getPrioritiesChoices() {
      const ticketPrioritiesChoices = this.ticketPrioritySettings.filter(
        (s) => s.value
      );
      if (
        ticketPrioritiesChoices.length === this.ticketPrioritySettings.length
      ) {
        return "All Priorities";
      }
      const [firstChoice] = ticketPrioritiesChoices;
      if (ticketPrioritiesChoices.length === 0) {
        return "No priorities selected";
      } else if (ticketPrioritiesChoices.length === 1) {
        return firstChoice.label;
      } else {
        const others = `${ticketPrioritiesChoices.length - 1}`;
        return `${firstChoice.label} + ${others}`;
      }
    },
    getTypesChoices() {
      const ticketTypeChoices = this.ticketTypeSettings.filter((s) => s.value);
      if (ticketTypeChoices.length === this.ticketTypeSettings.length) {
        return "All Types";
      }
      const [firstChoice] = ticketTypeChoices;
      if (ticketTypeChoices.length === 0) {
        return "No types selected";
      } else if (ticketTypeChoices.length === 1) {
        return firstChoice.label;
      } else {
        const others = `${ticketTypeChoices.length - 1}`;
        return `${firstChoice.label} + ${others} `;
      }
    },
    getDaysChoices() {
      const daysChoices = this.daysSettings.filter((s) => s.value);
      if (daysChoices.length === this.daysSettings.length) {
        return "All Days";
      }
      if (daysChoices.length === 0) {
        return "No days selected";
      } else {
        return daysChoices
          .map((s) => {
            const [initial] = s.label;
            return initial.toUpperCase();
          })
          .join(", ");
      }
    },
    getSelectHoursChoice() {
      const { hours } = this.workflowSettingBeingEdited.filter;
      const { all_hours: allHours } = hours;
      const { hoursFrom, hoursTo } = this;
      if (allHours) {
        return "All Hours";
      } else if (hoursFrom && hoursTo) {
        return `${hoursFrom} - ${hoursTo}`;
      }
      return "All Hours";
    },
    getSelectedLocateRequestAccountRegionChoiceName(
      locateRequestAccountRegionId
    ) {
      const label = this.locateRequestAccountRegionChoices.find(
        (c) => c.value === locateRequestAccountRegionId
      )?.label;
      return label ?? "Not Specified";
    },
    getSelectedAssignedToUserNames(assignToUserIds) {
      const labels = this.assignToUserChoices
        .filter((a) => {
          return assignToUserIds.includes(a.value);
        })
        .map((a) => {
          return a.label;
        });

      if (labels.length === this.assignToUserChoices.length) {
        return "All Users";
      }

      if (labels.length > 0) {
        const [label] = labels;
        const otherLabels = labels.slice(1);
        const plusNumber =
          otherLabels.length > 0 ? `+ ${otherLabels.length}` : "";
        return `${label} ${plusNumber}`.trim();
      }
      return "Not Specified";
    },
    onAssignedToUserIdsChange() {
      const allUsersSelected =
        this.workflowSettingBeingEdited.filter.assigned_to_user_ids.findIndex(
          (a) => a === ""
        ) >= 0;
      if (allUsersSelected) {
        const allAssignToUserIds = this.assignToUserChoices.map((a) => a.value);
        this.$set(
          this.workflowSettingBeingEdited.filter,
          "assigned_to_user_ids",
          [...allAssignToUserIds]
        );
      }
      this.$emit("input", cloneDeep(this.workflowSettingBeingEdited));
    },
    async populateTicketTypeSettings(ticketTypes) {
      if (!Array.isArray(ticketTypes)) {
        return;
      }
      const { ticket_types: ticketTypeFilters } =
        this.workflowSettingBeingEdited.filter ?? {};
      this.ticketTypeSettings = ticketTypes.map((t) => {
        const { ticket_type: label } = t;
        return { label, value: ticketTypeFilters?.includes?.(label) };
      });
    },
    async populateTicketPrioritySettings(ticketPriorities) {
      if (!Array.isArray(ticketPriorities)) {
        return;
      }
      const { priorities } = this.workflowSettingBeingEdited.filter ?? {};
      this.ticketPrioritySettings = ticketPriorities.map((t) => {
        const { ticket_priority: label } = t;
        return { label, value: priorities?.includes?.(label) };
      });
    },
    populateDaysSettings() {
      const { days } = this.workflowSettingBeingEdited.filter ?? {};
      this.daysSettings = daysSettings.map((s) => {
        const { label } = s;
        return { label, value: Boolean(days[label]) };
      });
    },
    populateHoursSettings() {
      const { from, to } = this.workflowSettingBeingEdited?.filter?.hours ?? {};
      const [fromTimeZone] = from?.match?.(TIME_ZONE_REGEX) ?? [];
      const [toTimeZone] = to?.match?.(TIME_ZONE_REGEX) ?? [];
      const fromWithoutTimeZone = from?.replaceAll?.(TIME_ZONE_REGEX, "");
      const toWithoutTimeZone = to?.replaceAll?.(TIME_ZONE_REGEX, "");
      const fromMoment = moment(fromWithoutTimeZone, "hh:mm A");
      const toMoment = moment(toWithoutTimeZone, "hh:mm A");
      if (fromMoment.isValid()) {
        this.from = {
          hour: fromMoment.format("hh"),
          minute: fromMoment.format("mm"),
          period: fromMoment.format("A"),
          timeZone: fromTimeZone,
        };
      }
      if (toMoment.isValid()) {
        this.to = {
          hour: toMoment.format("hh"),
          minute: toMoment.format("mm"),
          period: toMoment.format("A"),
          timeZone: toTimeZone,
        };
      }
    },
  },
  watch: {
    assignToUserChoices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.populateAssignedToFilter();
        }
      },
    },
    hoursFrom(val) {
      if (val) {
        this.onChange();
      }
    },
    hoursTo(val) {
      if (val) {
        this.onChange();
      }
    },
  },
};
</script>

<style scoped>
.drop-down {
  width: 250px;
  max-width: 250px;
}

.hour-drop-down {
  width: 60px;
  max-width: 60px;
}
</style>
