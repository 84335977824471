var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "my-1 caption" }, [_vm._v("Access")]),
      _c(
        "v-list",
        [
          _c("DepartmentsAccessForm", {
            attrs: { selectedMap: _vm.selectedMap },
            on: {
              "access-updated": function ($event) {
                return _vm.$emit("access-updated", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }