var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showLayerMapDepartmentAccessDialog,
        "max-width": "600px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [_c("v-toolbar-title", [_vm._v(" Layer Access ")])],
            1
          ),
          _c("v-card-text", { staticClass: "pa-4" }, [
            _vm._v(
              " The layer(s) selected does not currently have access to the department(s) that the map has access to. If you proceed, the department(s) will be added to the layer(s) so that they will be visible in this map. "
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end gap px-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update-layer-departments")
                    },
                  },
                },
                [_vm._v(" Update Layers And Add To Map ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }