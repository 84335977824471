<template>
  <v-dialog
    :value="showProvideSeparatePositiveResponseDialog"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-text class="py-3">
        When this option is enabled, it will show each facility in the positive
        response form question and the locator will be required to provide a
        positive response for each facility. When this optiopn is disable, the
        locator will provide a single positive response, and it will be sent for
        each facility listed.
      </v-card-text>
      <v-card-actions
        class="d-flex justify-end py-1 px-3"
        @click="$emit('close-provide-separate-response-dialog')"
      >
        <v-btn text color="primary" class="font-weight-regular">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProvideSeparatePositiveResponseDialog",
  props: {
    showProvideSeparatePositiveResponseDialog: Boolean,
  },
};
</script>
