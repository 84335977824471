var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2" },
    [
      _c("validation-observer", { ref: "addLayersToMapForm" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "v-toolbar",
              {
                staticClass: "elevation-0",
                attrs: { dark: "", color: "primary" },
              },
              [
                _c("v-toolbar-title", [_vm._v("Add Layers to Map")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("add-layers-to-map-form-close")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              { staticClass: "pl-8 pr-8" },
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.layersNotInMap,
                    "hide-default-footer": "",
                    "disable-pagination": "",
                    search: _vm.search,
                    "custom-filter": _vm.filterLayers,
                    "item-key": "map_service_id",
                    "show-select": "",
                    height: "50vh",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            [
                              _vm.selectedLayers.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-end",
                                      staticStyle: {
                                        "background-color": "#fff8e1",
                                      },
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: _vm.onAddLayersButtonClick,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiLayersPlus) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Add Layers "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex mx-auto",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mr-6",
                                    attrs: {
                                      label: "Search",
                                      "hide-details": "auto",
                                      color: "primary",
                                      name: "search",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiMagnify)),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.selectedLayers,
                    callback: function ($$v) {
                      _vm.selectedLayers = $$v
                    },
                    expression: "selectedLayers",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("LayerMapDepartmentAccessDialog", {
        attrs: {
          showLayerMapDepartmentAccessDialog:
            _vm.showLayerMapDepartmentAccessDialog,
        },
        on: {
          cancel: function ($event) {
            _vm.showLayerMapDepartmentAccessDialog = false
          },
          "update-layer-departments": _vm.updateLayerDepartmentsAndAddToMap,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }