<template>
  <div>
    <div class="my-1 caption">Access</div>

    <v-list>
      <DepartmentsAccessForm
        :selectedMap="selectedMap"
        @access-updated="$emit('access-updated', $event)"
      />
    </v-list>
  </div>
</template>

<script>
import DepartmentsAccessForm from "@/components/map/edit-map-form/access-form/DepartmentsAccessForm.vue";

export default {
  name: "AccessForm",
  components: { DepartmentsAccessForm },
  props: {
    selectedMap: Object,
  },
};
</script>
