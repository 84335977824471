<template>
  <v-card class="m-2">
    <validation-observer ref="addLayersToMapForm">
      <form @submit.prevent>
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Add Layers to Map</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('add-layers-to-map-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-8 pr-8">
          <v-data-table
            v-model="selectedLayers"
            :headers="headers"
            :items="layersNotInMap"
            hide-default-footer
            disable-pagination
            :search="search"
            :custom-filter="filterLayers"
            item-key="map_service_id"
            show-select
            height="50vh"
          >
            <template v-slot:top>
              <v-row>
                <v-col
                  style="background-color: #fff8e1"
                  cols="12"
                  class="d-flex justify-end"
                  v-if="selectedLayers.length > 0"
                >
                  <v-btn text color="primary" @click="onAddLayersButtonClick">
                    <v-icon class="mr-2">
                      {{ mdiLayersPlus }}
                    </v-icon>
                    Add Layers
                  </v-btn>
                </v-col>

                <v-col cols="12" class="d-flex mx-auto">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    hide-details="auto"
                    color="primary"
                    name="search"
                    class="mr-6"
                  >
                    <template v-slot:append>
                      <v-icon>{{ mdiMagnify }}</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </form>
    </validation-observer>

    <LayerMapDepartmentAccessDialog
      :showLayerMapDepartmentAccessDialog="showLayerMapDepartmentAccessDialog"
      @cancel="showLayerMapDepartmentAccessDialog = false"
      @update-layer-departments="updateLayerDepartmentsAndAddToMap"
    />
  </v-card>
</template>

<script>
import { mdiMagnify, mdiLayersPlus } from "@mdi/js";
import axios from "axios";
import LayerMapDepartmentAccessDialog from "@/components/map/edit-map-form/add-layers-to-map-form/LayerMapDepartmentAccessDialog.vue";
import { isEqual } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Layers",
    align: "start",
    sortable: false,
    value: "service_name",
  },
];

export default {
  name: "AddLayersToMapForm",
  props: {
    map: Object,
  },
  data() {
    return {
      layersNotInMap: [],
      headers,
      selectedLayers: [],
      search: "",
      mdiMagnify,
      mdiLayersPlus,
      departments: [],
      departmentsMaps: [],
      departmentMapServices: [],
      showLayerMapDepartmentAccessDialog: false,
    };
  },
  components: { LayerMapDepartmentAccessDialog },
  computed: {
    layersHaveAllDepartmentAccessMapHas() {
      const { selectedLayers, departmentMapServices, departmentsMaps } = this;
      const allLayersHaveSameDepartmentAccessAsMap = selectedLayers.every(
        (l) => {
          const departmentMapServiceDepartmentIds = departmentMapServices
            .filter((dms) => dms.map_service_id === l.map_service_id)
            .map((d) => d.department_id);
          const departmentMapDepartmentIds = departmentsMaps
            .filter((dm) => dm.map_id === this.map.map_id)
            .map((dm) => dm.department_id);
          const sortedDepartmentMapServiceDepartmentIds = [
            ...new Set(departmentMapServiceDepartmentIds),
          ].sort((a, b) => a.localeCompare(b));
          const sortedDepartmentMapDepartmentIds = [
            ...new Set(departmentMapDepartmentIds),
          ].sort((a, b) => a.localeCompare(b));

          return (
            sortedDepartmentMapDepartmentIds.length === 0 ||
            isEqual(
              sortedDepartmentMapServiceDepartmentIds,
              sortedDepartmentMapDepartmentIds
            )
          );
        }
      );
      return allLayersHaveSameDepartmentAccessAsMap;
    },
  },
  methods: {
    async updateLayerDepartmentsAndAddToMap() {
      const { selectedLayers, departmentMapServices, departmentsMaps } = this;
      const unflattenedDepartmentIdsToInsertToDepartmentMaps =
        await Promise.all(
          selectedLayers.map(async (l) => {
            const departmentLayerAccess = departmentMapServices.filter(
              (dms) => dms.map_service_id === l.map_service_id
            );
            const departmentIdsToInsertToDepartmentMaps =
              departmentLayerAccess.map((d) => d.department_id);
            const departmentIdsToInsertToDepartmentMapServices =
              departmentsMaps.map((dm) => dm.department_id);
            const departmentMapServicesToInsert =
              departmentIdsToInsertToDepartmentMapServices.map(
                (departmentId) => {
                  return {
                    map_service_id: l.map_service_id,
                    department_id: departmentId,
                  };
                }
              );
            await axios.post(`${APIURL}/department_map_services/batch`, {
              department_map_services: departmentMapServicesToInsert,
            });
            return departmentIdsToInsertToDepartmentMaps;
          })
        );
      const departmentIdsToInsertToDepartmentMaps = [
        ...new Set(unflattenedDepartmentIdsToInsertToDepartmentMaps.flat()),
      ];
      const departmentMapsToInsert = departmentIdsToInsertToDepartmentMaps.map(
        (departmentId) => {
          return {
            map_id: this.map.map_id,
            department_id: departmentId,
          };
        }
      );
      await axios.post(`${APIURL}/department_maps/batch`, {
        department_maps: departmentMapsToInsert,
      });
      await this.addLayersToMap();
      this.$emit("add-layers-to-map-form-submitted");
    },
    async getDepartmentsMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps`);
      this.departmentsMaps = results;
    },
    async getDepartmentMapServices() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_map_services`);
      this.departmentMapServices = results;
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getLayersNotInMap() {
      const { map_id: mapId } = this.map;
      const params = {
        map_id: mapId,
      };
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/notinmap`, { params });
      this.layersNotInMap = results.filter(
        (l) => l.is_active && l.service_type !== "R"
      );
    },
    async onAddLayersButtonClick() {
      const { layersHaveAllDepartmentAccessMapHas } = this;
      if (!layersHaveAllDepartmentAccessMapHas && this.departments.length > 0) {
        this.showLayerMapDepartmentAccessDialog = true;
        return;
      }
      await this.addLayersToMap();
    },
    async addLayersToMap() {
      const { map_id: mapId } = this.map;
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const params = {
        map_id: mapId,
        user_group_id: userGroupId,
      };
      const {
        data: { results: layersInMap },
      } = await axios.get(`${APIURL}/map_services/inmap`, {
        params,
      });
      const mapServices = [...layersInMap, ...this.selectedLayers].map(
        ({ map_service_id: mapServiceId }, index) => ({
          map_service_id: mapServiceId,
          is_visible: true,
          display_order: index + 1,
        })
      );
      await axios.put(`${APIURL}/maps/${mapId}/services`, {
        map_services: mapServices,
      });
      await this.getLayersNotInMap();
      this.$emit("add-layers-to-map-form-submitted");
    },
    filterLayers(value, search) {
      return (
        typeof value === "string" &&
        typeof search === "string" &&
        value.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
  },
  beforeMount() {
    this.getLayersNotInMap();
  },
  watch: {
    map: {
      deep: true,
      immediate: true,
      async handler() {
        await this.getDepartments();
        await this.getDepartmentsMaps();
        await this.getDepartmentMapServices();
      },
    },
  },
};
</script>
