<template>
  <v-dialog
    :value="showLayerMapDepartmentAccessDialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
        <v-toolbar-title> Layer Access </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        The layer(s) selected does not currently have access to the
        department(s) that the map has access to. If you proceed, the
        department(s) will be added to the layer(s) so that they will be visible
        in this map.
      </v-card-text>
      <v-card-actions class="d-flex justify-end gap px-4">
        <v-btn text color="primary" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('update-layer-departments')">
          Update Layers And Add To Map
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LayerMapDepartmentAccessDialog",
  props: {
    showLayerMapDepartmentAccessDialog: Boolean,
  },
};
</script>
