var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "section",
            { staticClass: "d-flex align-center justify-space-between" },
            [
              _c("div", [_vm._v("Departments")]),
              _c(
                "div",
                { staticClass: "d-flex gap" },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.mapIsInAllDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addMapToAllDepartments()
                        },
                      },
                    },
                    [_vm._v(" All ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.mapIsInSomeDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showMapDepartmentAccessDialog = true
                        },
                      },
                    },
                    [_vm._v(" Selected ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.mapIsInNoDepartments ? "primary" : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeMapFromAllDepartments()
                        },
                      },
                    },
                    [_vm._v(" None ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.showMapDepartmentAccessDialog
            ? _c("MapDepartmentAccessDialog", {
                attrs: {
                  showMapDepartmentAccessDialog:
                    _vm.showMapDepartmentAccessDialog,
                  selectedMap: _vm.selectedMap,
                  departmentsThatHaveAccessToMap:
                    _vm.newDepartmentsThatHaveAccessToMap,
                },
                on: {
                  "department-dialog-close": function ($event) {
                    _vm.showMapDepartmentAccessDialog = false
                  },
                  "access-updated": _vm.onAccessUpdated,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }