<template>
  <v-list-item>
    <v-list-item-content>
      <section class="d-flex align-center justify-space-between">
        <div>Departments</div>

        <div class="d-flex gap">
          <v-chip
            :color="mapIsInAllDepartments ? 'primary' : undefined"
            @click="addMapToAllDepartments()"
          >
            All
          </v-chip>
          <v-chip
            :color="mapIsInSomeDepartments ? 'primary' : undefined"
            @click="showMapDepartmentAccessDialog = true"
          >
            Selected
          </v-chip>
          <v-chip
            :color="mapIsInNoDepartments ? 'primary' : undefined"
            @click="removeMapFromAllDepartments()"
          >
            None
          </v-chip>
        </div>
      </section>

      <MapDepartmentAccessDialog
        v-if="showMapDepartmentAccessDialog"
        :showMapDepartmentAccessDialog="showMapDepartmentAccessDialog"
        :selectedMap="selectedMap"
        :departmentsThatHaveAccessToMap="newDepartmentsThatHaveAccessToMap"
        @department-dialog-close="showMapDepartmentAccessDialog = false"
        @access-updated="onAccessUpdated"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import MapDepartmentAccessDialog from "@/components/map/edit-map-form/access-form/department-access-form/MapDepartmentAccessDialog.vue";
import axios from "axios";
import { cloneDeep } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentsAccessForm",
  props: {
    selectedMap: Object,
  },
  components: { MapDepartmentAccessDialog },
  data() {
    return {
      departments: [],
      departmentMaps: [],
      departmentsMapIsIn: [],
      showMapDepartmentAccessDialog: false,
      newDepartmentsThatHaveAccessToMap: [],
    };
  },
  computed: {
    mapIsInAllDepartments() {
      return (
        this.departments.length === 0 ||
        this.newDepartmentsThatHaveAccessToMap.length ===
          this.departments.length
      );
    },
    mapIsInSomeDepartments() {
      return (
        this.newDepartmentsThatHaveAccessToMap.length > 0 &&
        this.newDepartmentsThatHaveAccessToMap.length < this.departments.length
      );
    },
    mapIsInNoDepartments() {
      return !this.mapIsInAllDepartments && !this.mapIsInSomeDepartments;
    },
  },
  methods: {
    async getDepartmentsThatHaveAccessToMap(mapId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps/map/${mapId}`);
      this.departmentsMapIsIn = results;
      this.newDepartmentsThatHaveAccessToMap = cloneDeep(
        this.departmentsMapIsIn
      );
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async addMapToAllDepartments() {
      this.newDepartmentsThatHaveAccessToMap = this.departments.map((d) => {
        const { department_id: departmentId } = d;
        return {
          map_id: this.selectedMap.map_id,
          department_id: departmentId,
        };
      });
      this.$emit("access-updated", this.newDepartmentsThatHaveAccessToMap);
    },
    async removeMapFromAllDepartments() {
      this.newDepartmentsThatHaveAccessToMap = [];
      this.$emit("access-updated", this.newDepartmentsThatHaveAccessToMap);
    },
    async onAccessUpdated(newDepartmentsThatHaveAccessToMap) {
      this.showMapDepartmentAccessDialog = false;
      this.newDepartmentsThatHaveAccessToMap =
        newDepartmentsThatHaveAccessToMap;
      this.$emit("access-updated", newDepartmentsThatHaveAccessToMap);
    },
  },
  beforeMount() {
    this.getDepartments();
  },
  watch: {
    "selectedMap.map_id": {
      immediate: true,
      handler(mapId) {
        this.getDepartmentsThatHaveAccessToMap(mapId);
      },
    },
  },
};
</script>
