var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { value: _vm.showMapDepartmentAccessDialog, "max-width": "600px" },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Department Access")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("department-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              ref: "cardText",
              staticClass: "py-2",
              style: {
                "background-color": "#f1f2f1",
                "overflow-y": "auto",
                height: "65vh",
              },
            },
            [
              _c("p", { ref: "cardInnerText" }, [
                _c("b", [
                  _vm._v(
                    "The map can be accessed by the following departments:"
                  ),
                ]),
              ]),
              _c(
                "v-simple-table",
                {
                  attrs: { "fixed-header": "", height: `${_vm.tableHeight}px` },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        [
                          _c("v-simple-checkbox", {
                            attrs: {
                              value:
                                _vm.newDepartmentsThatHaveAccessToMap.length ===
                                _vm.departments.length,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.toggleAllDepartmentsAccessToMap()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("th", [_vm._v("Departments")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.departments, function (d) {
                      return _c("tr", { key: d.department_id }, [
                        _c(
                          "td",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                value: _vm.departmentHasAccessToMap(
                                  d.department_id
                                ),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.toggleDepartmentAccessToMap(
                                    d.department_id
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(d.name))]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _c("UpdateAllLayerDepartmentAccessDialog", {
                attrs: {
                  showUpdateAllLayerDepartmentAccessDialog:
                    _vm.showUpdateAllLayerDepartmentAccessDialog,
                },
                on: {
                  cancel: function ($event) {
                    _vm.showUpdateAllLayerDepartmentAccessDialog = false
                  },
                  "update-all-layer-departments": function ($event) {
                    return _vm.onUpdateAllLayerDepartments(
                      _vm.departmentIdBeingToggled
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "access-updated",
                        _vm.newDepartmentsThatHaveAccessToMap
                      )
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }