<template>
  <v-card class="m-2">
    <validation-observer ref="createMapForm">
      <form @submit.prevent="createMap">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Create Map</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('create-map-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-8 pr-8">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Map Name"
                :rules="{ min: 1, max: 50 }"
              >
                <v-text-field
                  v-model="map.name"
                  label="Map Name"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  id="mapName"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="primary"
                type="submit"
                :disabled="!map.name.length || map.name.length > 50"
                id="createMap"
                >Create Map</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CreateMapForm",
  data() {
    return {
      map: {
        center_x_coord: "",
        center_y_coord: "",
        current_basemap_service_id: "",
        name: "",
        photo_service_id: "",
        sketch_line_service_id: "",
        sketch_point_service_id: "",
        sketch_poly_service_id: "",
        user_group_id: "",
        wkid: "",
        zoom_level: "",
      },
    };
  },
  methods: {
    async createMap() {
      const { map } = this;
      const success = await this.$refs.createMapForm.validate();
      if (!success) {
        return;
      }
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      map.user_group_id = userGroupId;
      await axios.post(`${APIURL}/maps`, map);
      this.$emit("create-map-form-submitted");
      map.name = "";
    },
  },
};
</script>
