<template>
  <v-card style="background-color: #f1f2f1">
    <validation-observer ref="editMapForm" v-slot="{ valid: isFormValid }">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title>Edit Map</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-map-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          class="px-8"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
            'overflow-y': 'auto',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
        >
          <v-card class="my-1">
            <v-card-text>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Map Name"
                :rules="{ min: 1, max: 50, required: true }"
              >
                <v-text-field
                  v-model="map.name"
                  label="Map Name"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  id="mapName"
                  :disabled="!canManageMaps"
                />
              </validation-provider>
            </v-card-text>
          </v-card>

          <div class="my-1 text-caption">Layers</div>

          <v-card id="attachedLayers">
            <v-card-text>
              <v-text-field
                v-model="search"
                label="Search"
                hide-details="auto"
                color="primary"
                name="search"
                class="mr-6"
              >
                <template v-slot:append>
                  <v-icon>{{ mdiMagnify }}</v-icon>
                </template>
              </v-text-field>

              <v-btn
                color="primary"
                class="mt-4"
                @click="showAddLayerDialog = true"
                id="addLayers"
                :disabled="!canManageMaps"
              >
                <v-icon class="mr-2">
                  {{ mdiLayersPlus }}
                </v-icon>
                Add Layers
              </v-btn>

              <v-data-table
                v-model="selectedLayers"
                :headers="headers"
                :items="layers"
                hide-default-footer
                disable-pagination
                :search="search"
                :custom-filter="filterLayers"
                item-key="map_service_id"
              >
                <template v-slot:top>
                  <v-row
                    v-if="selectedLayers.length > 0"
                    style="background-color: #fff8e1"
                    class="ml-0 mr-0"
                  >
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn text color="primary" :disabled="!canManageMaps">
                        <v-icon class="mr-2">
                          {{ mdiLayersRemove }}
                        </v-icon>
                        Remove Layers
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:body="{ items }">
                  <draggable
                    :list="items"
                    tag="tbody"
                    handle=".section-handle"
                    @end="onDragEnd(items)"
                  >
                    <tr v-for="item in items" :key="item.map_service_id">
                      <td class="pa-0 ma-0">
                        <v-icon
                          class="section-handle cursor-pointer"
                          v-if="canManageMaps"
                        >
                          {{ mdiDragVertical }}
                        </v-icon>
                      </td>
                      <td>{{ item.service_name }}</td>
                      <td>
                        <v-btn
                          icon
                          v-if="item.is_visible"
                          @click="item.is_visible = !item.is_visible"
                          :disabled="!canManageMaps"
                        >
                          <v-icon>{{ mdiEye }}</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          v-else
                          @click="item.is_visible = !item.is_visible"
                        >
                          <v-icon>{{ mdiEyeOff }}</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-right">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              id="overflowOptions"
                            >
                              <v-icon color="primary">
                                mdi-dots-vertical
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              class="cursor-pointer"
                              @click="removeLayer(item)"
                              :disabled="!canManageMaps"
                            >
                              <v-list-item-icon>
                                <v-icon>
                                  {{ mdiLayersRemove }}
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="ml-n5">
                                Remove Layer
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              class="cursor-pointer"
                              @click="
                                showEditLayerDialog = true;
                                selectedLayer = item;
                              "
                            >
                              <v-list-item-icon>
                                <v-icon>
                                  {{ mdiCog }}
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="ml-n5">
                                Layer Settings
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <AccessForm
            v-if="departments.length > 0"
            :selectedMap="selectedMap"
            @access-updated="newDepartmentsThatHaveAccessToMap = $event"
          />

          <v-dialog
            v-model="showAddLayerDialog"
            max-width="600px"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <AddLayersToMapForm
              @add-layers-to-map-form-close="showAddLayerDialog = false"
              @add-layers-to-map-form-submitted="onAddLayersToMapFormSubmitted"
              :map="map"
              id="addLayersDialog"
              v-if="showAddLayerDialog"
            />
          </v-dialog>

          <v-dialog
            v-model="showEditLayerDialog"
            max-width="600px"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <EditLayerForm
              v-if="showEditLayerDialog"
              @edit-layer-form-close="showEditLayerDialog = false"
              @edit-layer-form-submitted="showEditLayerDialog = false"
              :selectedLayer="selectedLayer"
            />
          </v-dialog>
        </v-card-text>

        <v-card-actions
          class="d-flex justify-end align-center pa-5"
          ref="cardAction"
        >
          <v-btn
            color="primary"
            type="submit"
            id="saveMapBtn"
            :disabled="!isFormValid || !canManageMaps"
          >
            Save Map
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import {
  mdiMagnify,
  mdiLayersPlus,
  mdiCog,
  mdiLayersRemove,
  mdiEye,
  mdiEyeOff,
  mdiDragVertical,
} from "@mdi/js";
import axios from "axios";
import draggable from "vuedraggable";
import AddLayersToMapForm from "@/components/map/edit-map-form/AddLayersToMapForm.vue";
import EditLayerForm from "@/components/layers/EditLayerForm.vue";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import AccessForm from "@/components/map/edit-map-form/AccessForm.vue";
import { cloneDeep } from "lodash";
import { isEqual } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const headers = [
  {
    text: "",
    align: "start",
    sortable: false,
    value: "",
    width: 10,
  },
  {
    text: "Layers",
    align: "start",
    sortable: false,
    value: "service_name",
  },
  {
    text: "Visible",
    align: "start",
    sortable: false,
    value: "is_visible",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "EditMapForm",
  props: {
    selectedMap: Object,
  },
  components: {
    draggable,
    AddLayersToMapForm,
    EditLayerForm,
    AccessForm,
  },
  mixins: [contentHeightMixin, permissionsMixin],
  data() {
    return {
      map: {},
      search: "",
      mdiMagnify,
      mdiLayersPlus,
      mdiCog,
      mdiLayersRemove,
      mdiEye,
      mdiEyeOff,
      mdiDragVertical,
      selectedLayers: [],
      headers,
      layers: [],
      showAddLayerDialog: false,
      showEditLayerDialog: false,
      departmentsThatHaveAccessToMap: [],
      newDepartmentsThatHaveAccessToMap: [],
      departments: [],
    };
  },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getDepartmentsThatHaveAccessToMap(mapId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps/map/${mapId}`);
      this.departmentsThatHaveAccessToMap = results;
      this.newDepartmentsThatHaveAccessToMap = cloneDeep(
        this.departmentsThatHaveAccessToMap
      );
    },
    onAddLayersToMapFormSubmitted() {
      this.showAddLayerDialog = false;
      this.getLayersInMap();
    },
    onDragEnd(reorderedItems) {
      const layers = [...reorderedItems];
      for (const [index] of layers.entries()) {
        layers[index].display_order = index + 1;
      }
      this.layers = layers;
    },
    filterLayers(value, search) {
      return (
        typeof value === "string" &&
        typeof search === "string" &&
        value.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
    async getLayersInMap() {
      const { map_id: mapId } = this.map;
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const params = {
        map_id: mapId,
        user_group_id: userGroupId,
      };
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/inmap`, {
        params,
      });
      this.layers = [...results]
        .reverse()
        .map((r, index) => ({
          ...r,
          display_order: index + 1,
        }))
        .sort((a, b) => +a.display_order - +b.display_order);
    },
    async submit() {
      await this.updateMap();
      await this.updateLayers(true);
      await this.updateDepartmentsThatHaveAccessToMap();
      this.$emit("edit-map-form-submitted");
    },
    async updateMap() {
      const { map_id: mapId } = this.map;
      await axios.put(`${APIURL}/maps/${mapId}`, this.map);
    },
    async updateLayers() {
      const { map_id: mapId } = this.map;
      const { layers } = this;
      const mapServices = [...layers]
        .reverse()
        .map(
          ({ map_service_id: mapServiceId, is_visible: isVisible }, index) => ({
            map_service_id: mapServiceId,
            is_visible: isVisible,
            display_order: index + 1,
          })
        );
      await axios.put(`${APIURL}/maps/${mapId}/services`, {
        map_services: mapServices,
      });
    },
    async removeLayer(selectedLayer) {
      const { map_service_id: selectedMapServiceId } = selectedLayer;
      this.layers = [
        ...this.layers.filter(
          ({ map_service_id: mapServiceId }) =>
            selectedMapServiceId !== mapServiceId
        ),
      ];
      for (const [index] of this.layers.entries()) {
        this.layers[index].display_order = index + 1;
      }
      await this.updateLayers(false);
      await this.getLayersInMap();
    },
    async updateDepartmentsThatHaveAccessToMap() {
      const {
        newDepartmentsThatHaveAccessToMap,
        departmentsThatHaveAccessToMap,
      } = this;
      const departmentMapsToInsert = newDepartmentsThatHaveAccessToMap.filter(
        (newDepartmentMap) => {
          return !departmentsThatHaveAccessToMap.find((oldDepartmentMap) => {
            return isEqual(oldDepartmentMap, newDepartmentMap);
          });
        }
      );
      await axios.post(`${APIURL}/department_maps/batch`, {
        department_maps: departmentMapsToInsert,
      });
      const departmentMapsToDelete = departmentsThatHaveAccessToMap.filter(
        (oldDepartmentMap) => {
          return !newDepartmentsThatHaveAccessToMap.find((newDepartmentMap) => {
            return isEqual(oldDepartmentMap, newDepartmentMap);
          });
        }
      );
      await axios.delete(`${APIURL}/department_maps/batch`, {
        data: {
          department_maps: departmentMapsToDelete,
        },
      });
      const departmentServicesToDeletePromises = this.layers.map(async (l) => {
        const departmentServicesToDelete = departmentsThatHaveAccessToMap.map(
          (d) => {
            return {
              map_service_id: l.map_service_id,
              department_id: d.department_id,
            };
          }
        );
        await axios.delete(`${APIURL}/department_map_services/batch`, {
          data: {
            department_map_services: departmentServicesToDelete,
          },
        });
      });
      await Promise.all(departmentServicesToDeletePromises);
      const departmentServicesToInsertPromises = this.layers.map(async (l) => {
        const departmentServicesToInsert =
          newDepartmentsThatHaveAccessToMap.map((d) => {
            return {
              map_service_id: l.map_service_id,
              department_id: d.department_id,
            };
          });
        await axios.post(`${APIURL}/department_map_services/batch`, {
          department_map_services: departmentServicesToInsert,
        });
      });
      await Promise.all(departmentServicesToInsertPromises);
      this.$emit("saved");
    },
  },
  watch: {
    selectedMap: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (val) {
          this.map = { ...val };
          await this.getLayersInMap();
          await this.getDepartmentsThatHaveAccessToMap(val.map_id);
        }
      },
    },
  },
  beforeMount() {
    this.getDepartments();
  },
};
</script>
