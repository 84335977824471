<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Maps" />
      <PageLayout>
        <v-data-table
          :headers="headers"
          :items="maps"
          hide-default-footer
          :search="search"
          disable-pagination
          class="py-0 my-0 cursor-pointer"
          single-select
          style="overflow-x: hidden !important"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-end gap px-2">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    hide-details="auto"
                    color="primary"
                    name="search"
                  >
                    <template v-slot:append>
                      <v-icon>{{ mdiMagnify }}</v-icon>
                    </template>
                  </v-text-field>

                  <div>
                    <v-btn
                      color="primary"
                      class="mt-4"
                      @click="showCreateMapDialog = true"
                      id="addMap"
                      :disabled="!canManageMaps"
                    >
                      + Map
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item of items"
                :key="item.map_id"
                :class="{ selectedRow: item === selectedMap }"
              >
                <td @click.stop="onMapRowClick(item)">{{ item.name }}</td>
                <td class="text-right">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" id="mapOptionsMenu">
                        <v-icon color="primary"> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list id="mapOptions">
                      <v-list-item
                        class="cursor-pointer"
                        @click="onMapRowClick(item)"
                      >
                        <v-icon class="mr-1">
                          {{ mdiPencil }}
                        </v-icon>
                        Edit Map
                      </v-list-item>
                      <v-list-item
                        class="cursor-pointer"
                        @click="
                          showConfirmDeleteMapDialog = true;
                          mapToDelete = item;
                        "
                        :disabled="!canManageMaps"
                      >
                        <v-icon class="mr-1">
                          {{ mdiDelete }}
                        </v-icon>
                        Delete Map
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <v-dialog v-model="showCreateMapDialog" max-width="600px" persistent>
          <CreateMapForm
            @create-map-form-close="showCreateMapDialog = false"
            @create-map-form-submitted="
              showCreateMapDialog = false;
              getMaps();
            "
          />
        </v-dialog>

        <v-dialog
          v-model="showEditMapDialog"
          max-width="600px"
          persistent
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <EditMapForm
            @edit-map-form-close="
              showEditMapDialog = false;
              $router.push({
                name: 'Maps',
                query: {},
              });
            "
            @edit-map-form-submitted="
              showEditMapDialog = false;
              getMaps();
              $router.push({
                name: 'Maps',
                query: {},
              });
            "
            :selectedMap="selectedMap"
          />
        </v-dialog>

        <v-dialog
          v-model="showConfirmDeleteMapDialog"
          max-width="600px"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <v-list-item>
              <v-list-item-content class="pl-3">
                <div class="text-h5 py-3">Delete Map?</div>
                Are you sure you want to delete this map? This action cannot be
                undone.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-end">
                  <v-btn
                    text
                    @click="
                      showConfirmDeleteMapDialog = false;
                      mapToDelete = undefined;
                    "
                    color="primary"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="primary" @click="deleteMap"> Delete Map </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-dialog>
      </PageLayout>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import CreateMapForm from "@/components/map/CreateMapForm.vue";
import EditMapForm from "@/components/map/EditMapForm.vue";
import { mdiMagnify, mdiPencil, mdiDelete } from "@mdi/js";
import PageLayout from "@/components/layouts/PageLayout.vue";
import axios from "axios";
import usetifulMixin from "@/mixins/usetifulMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Map Name",
    align: "start",
    sortable: false,
    value: "name",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "Map",
  components: {
    TopBar,
    CreateMapForm,
    EditMapForm,
    PageLayout,
  },
  mixins: [usetifulMixin, permissionsMixin],
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      maps: [],
      showCreateMapDialog: false,
      showEditMapDialog: false,
      selectedMap: {},
      headers,
      showConfirmDeleteMapDialog: false,
      mapToDelete: undefined,
    };
  },
  methods: {
    onMapRowClick(item) {
      this.showEditMapDialog = true;
      this.selectedMap = item;
      this.$router.push({
        name: "Maps",
        query: { map: this.selectedMap.map_id },
      });
    },
    async getMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps`);
      this.maps = results;
    },
    async deleteMap() {
      if (!this.mapToDelete) {
        return;
      }
      const { map_id: mapId } = this.mapToDelete;
      await axios.delete(`${APIURL}/maps/${mapId}`);
      await this.getMaps();
      this.showConfirmDeleteMapDialog = false;
    },
  },
  async beforeMount() {
    await this.getMaps();
    if (this.$route.query?.map) {
      this.showEditMapDialog = true;
      this.selectedMap = this.maps.find(
        (m) => m.map_id === this.$route.query.map
      );
    }
  },
};
</script>

<style scoped>
.gap {
  gap: 15px;
}

.selectedRow {
  background-color: #f5f5f5;
}
</style>
