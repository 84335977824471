<template>
  <v-dialog
    :value="showUpdateAllLayerDepartmentAccessDialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
        <v-toolbar-title> Layer Access </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        One or more of these layers does not currently have access to the
        department(s) that this map has access to. If you proceed, these
        departments will be added to the layer, so that it will be visible in
        this map.
      </v-card-text>
      <v-card-actions class="d-flex justify-end gap px-4">
        <v-btn text color="primary" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('update-all-layer-departments')">
          Update All Layer Departments
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdateAllLayerDepartmentAccessDialog",
  props: {
    showUpdateAllLayerDepartmentAccessDialog: Boolean,
  },
};
</script>
