var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showProvideSeparatePositiveResponseDialog,
        "max-width": "400px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _vm._v(
              " When this option is enabled, it will show each facility in the positive response form question and the locator will be required to provide a positive response for each facility. When this optiopn is disable, the locator will provide a single positive response, and it will be sent for each facility listed. "
            ),
          ]),
          _c(
            "v-card-actions",
            {
              staticClass: "d-flex justify-end py-1 px-3",
              on: {
                click: function ($event) {
                  return _vm.$emit("close-provide-separate-response-dialog")
                },
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: { text: "", color: "primary" },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }