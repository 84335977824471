<template>
  <v-card class="m-2" style="background-color: #fafafa">
    <validation-observer ref="edit811AccountWorkflowsForm">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title> Workflows </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-811-workflows-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          class="px-8 py-2 overflow-y-auto"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 120px)' : '65vh',
          }"
        >
          <section class="d-flex justify-end">
            <v-btn
              text
              color="primary"
              @click="addWorkflowSetting"
              :disabled="!canManage811Codes"
            >
              <v-icon color="primary" small>{{ mdiPlus }}</v-icon>
              Workflow
            </v-btn>
          </section>

          <section
            v-if="workflowSettings.length === 0"
            class="d-flex justify-center align-center"
          >
            No Workflows added yet.
          </section>
          <section v-else>
            <v-expansion-panels
              class="w-100"
              v-model="openWorkflowSettings"
              multiple
            >
              <draggable
                :list="workflowSettings"
                group="workflowSettings"
                handle=".section-handle"
                class="w-100"
              >
                <EditWorkflowForm
                  :users="users"
                  :ticketTypes="ticketTypes"
                  :ticketPriorities="ticketPriorities"
                  :locateRequestAccountRegions="locateRequestAccountRegions"
                  v-model="workflowSettings[index]"
                  v-for="(workflowSetting, index) of workflowSettings"
                  :key="workflowSetting.id"
                  @delete-workflow="deleteWorkflow(index)"
                  class="w-100 my-0"
                  :class="{ 'border-top': index > 0 }"
                />
              </draggable>
            </v-expansion-panels>
          </section>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pa-3">
          <v-btn color="primary" type="submit" :disabled="!canManage811Codes">
            Save Workflows
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { mdiPlus } from "@mdi/js";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import EditWorkflowForm from "@/components/accounts-811/edit-workflows-form/EditWorkflowForm";
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const getEmailTemplate = () => {
  return [
    `A <span data-param="ticket_priority">{{ticket_priority}}</span> Locate Request has been received:`,
    `<br><br>`,
    `View Here: `,
    `<span data-param="ticket_link">{{ticket_link}}</span>`,
    `<br><br>`,
    `<span data-param="printable_text">{{printable_text}}</span>`,
  ].join("");
};

const getSmsTemplate = () => {
  return [
    `A <span data-param="ticket_priority">{{ticket_priority}}</span>  ticket has been received. View Here:  `,
    `<span data-param="ticket_link">{{ticket_link}}</span>`,
  ].join("");
};

const getPushNotificationTemplate = () => {
  return `A <span data-param="ticket_priority">{{ticket_priority}}</span> ticket has been received. Click here to view.`;
};

const DEFAULT_WORKFLOW_SETTING = {
  enabled: true,
  filter: {
    priorities: [],
    ticket_types: [],
    days: {
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    },
    hours: {
      all_hours: true,
      from: undefined,
      to: undefined,
    },
    locate_request_account_region_id: undefined,
  },
  actions: {
    assign_ticket: {
      enabled: false,
      assign_to_user_id: "",
    },
    send_email: {
      enabled: false,
      email_user_ids: [],
      message: getEmailTemplate(),
    },
    send_sms: {
      enabled: false,
      sms_user_ids: [],
      message: getSmsTemplate(),
    },
    send_push: {
      enabled: false,
      push_user_ids: [],
      message: getPushNotificationTemplate(),
    },
  },
};

export default {
  name: "EditWorkflowsForm",
  components: { draggable, EditWorkflowForm },
  props: {
    selected811Account: Object,
    locateRequestProviders: Array,
  },
  data() {
    return {
      workflowSettings: [],
      openWorkflowSettings: [],
      mdiPlus,
      users: [],
      ticketTypes: [],
      ticketPriorities: [],
      locateRequestAccountRegions: [],
    };
  },
  mixins: [permissionsMixin],
  methods: {
    async submit() {
      const success = await this.$refs.edit811AccountWorkflowsForm.validate();
      if (!success) {
        return;
      }
      const locateRequestProvider = this.locateRequestProviders.find(
        (l) =>
          l.locate_request_provider_id ===
          this.selected811Account.locate_request_provider_id
      );
      await axios.put(
        `${APIURL}/locate_request_provider_accounts/${this.selected811Account?.locate_request_provider_account_id}`,
        {
          ...this.selected811Account,
          provider_code: locateRequestProvider?.code,
          workflows: this.workflowSettings,
        }
      );
      this.$emit("workflows-saved");
    },
    addWorkflowSetting() {
      const newWorkflowSetting = {
        id: uuidv4(),
        ...DEFAULT_WORKFLOW_SETTING,
        filter: {
          ...DEFAULT_WORKFLOW_SETTING.filter,
          priorities: this.ticketPriorities.map((p) => p.ticket_priority),
          ticket_types: this.ticketTypes.map((t) => t.ticket_type),
        },
      };
      this.workflowSettings.push(newWorkflowSetting);
      const newWorkflowSettingIndex = this.workflowSettings.findIndex(
        (s) => s.id === newWorkflowSetting.id
      );
      this.openWorkflowSettings = [newWorkflowSettingIndex];
    },
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results;
    },
    async getTicketTypes(locateRequestProviderId) {
      if (!locateRequestProviderId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/ticket_types/${locateRequestProviderId}`);
      this.ticketTypes = results;
    },
    async getTicketPriorities(locateRequestProviderId) {
      if (!locateRequestProviderId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/ticket_priorities/${locateRequestProviderId}`
      );
      const normalPriority = {
        locate_request_provider_id: locateRequestProviderId,
        ticket_priority: "Normal",
      };
      this.ticketPriorities = [...results, normalPriority];
    },
    async getLocateRequestAccountRegions() {
      if (!this.selected811Account?.locate_request_provider_account_id) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_account_regions`, {
        params: {
          locate_request_provider_account_id:
            this.selected811Account?.locate_request_provider_account_id,
        },
      });
      this.locateRequestAccountRegions = results;
    },
    getWorkflowSettings() {
      if (Array.isArray(this.selected811Account?.workflows)) {
        this.workflowSettings = cloneDeep(this.selected811Account?.workflows);
        this.openWorkflowSettings = [];
      }
    },
    deleteWorkflow(index) {
      this.workflowSettings.splice(index, 1);
    },
  },
  beforeMount() {
    this.getUserGroupUsers();
  },
  watch: {
    selected811Account: {
      deep: true,
      immediate: true,
      async handler({ locate_request_provider_id: locateRequestProviderId }) {
        await this.getTicketTypes(locateRequestProviderId);
        await this.getTicketPriorities(locateRequestProviderId);
        await this.getLocateRequestAccountRegions();
        this.getWorkflowSettings();
      },
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
