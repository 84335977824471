<template>
  <v-dialog
    :value="showMapDepartmentAccessDialog"
    max-width="600px"
    ref="dialog"
  >
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Department Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('department-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          'overflow-y': 'auto',
          height: '65vh',
        }"
        class="py-2"
        ref="cardText"
      >
        <p ref="cardInnerText">
          <b>The map can be accessed by the following departments:</b>
        </p>

        <v-simple-table fixed-header :height="`${tableHeight}px`">
          <thead>
            <tr>
              <th>
                <v-simple-checkbox
                  :value="
                    newDepartmentsThatHaveAccessToMap.length ===
                    departments.length
                  "
                  @input="toggleAllDepartmentsAccessToMap()"
                ></v-simple-checkbox>
              </th>
              <th>Departments</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d of departments" :key="d.department_id">
              <td>
                <v-simple-checkbox
                  :value="departmentHasAccessToMap(d.department_id)"
                  @input="toggleDepartmentAccessToMap(d.department_id)"
                ></v-simple-checkbox>
              </td>
              <td>{{ d.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <UpdateAllLayerDepartmentAccessDialog
          :showUpdateAllLayerDepartmentAccessDialog="
            showUpdateAllLayerDepartmentAccessDialog
          "
          @cancel="showUpdateAllLayerDepartmentAccessDialog = false"
          @update-all-layer-departments="
            onUpdateAllLayerDepartments(departmentIdBeingToggled)
          "
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn
          color="primary"
          @click="$emit('access-updated', newDepartmentsThatHaveAccessToMap)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import axios from "axios";
import { isEqual } from "lodash";
import { cloneDeep } from "lodash";
import UpdateAllLayerDepartmentAccessDialog from "@/components/map/edit-map-form/access-form/department-access-form/map-department-access-dialog/UpdateAllLayerDepartmentAccessDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "MapDepartmentAccessDialog",
  mixins: [contentHeightMixin],
  props: {
    showMapDepartmentAccessDialog: Boolean,
    selectedMap: Object,
    departmentsThatHaveAccessToMap: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { UpdateAllLayerDepartmentAccessDialog },
  data() {
    return {
      departments: [],
      newDepartmentsThatHaveAccessToMap: [],
      departmentsMaps: [],
      departmentMapServices: [],
      mapServices: [],
      layersInMap: [],
      showUpdateAllLayerDepartmentAccessDialog: false,
      departmentIdBeingToggled: undefined,
      tableHeight: 0,
    };
  },
  methods: {
    getTableHeight() {
      this.$options.mutationObserver = new MutationObserver(() => {
        this.$options.resizeObserver = new ResizeObserver(() => {
          this.tableHeight =
            this.$refs.cardText?.clientHeight -
            this.$refs.cardInnerText?.clientHeight -
            40;
        });
        if (this.$refs.cardText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardText);
        }

        if (this.$refs.cardInnerText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardInnerText);
        }
      });
      const config = { attributes: true, childList: true, subtree: true };
      this.$options.mutationObserver.observe(document.body, config);
    },
    getMapServicesThatDontHaveAllDepartmentMapHas(mapId, newDepartmentId) {
      const newDepartmentMap = {
        department_id: newDepartmentId,
        map_id: mapId,
      };
      const departmentIdsInMap = [...this.departmentsMaps, newDepartmentMap]
        .filter((dm) => dm.map_id === mapId)
        .map((dm) => dm.department_id)
        .sort((a, b) => a.localeCompare(b));

      return this.layersInMap.filter((mapServiceId) => {
        const departmentIdsInMapService = this.departmentMapServices
          .filter((dms) => dms.map_service_id === mapServiceId)
          .map((dm) => dm.department_id)
          .sort((a, b) => a.localeCompare(b));
        return !isEqual(departmentIdsInMap, departmentIdsInMapService);
      });
    },
    async getMapServices() {
      let authResults;
      try {
        authResults = JSON.parse(localStorage.getItem("auth")) || {};
      } catch (error) {
        authResults = {};
      }

      const { user_group_id: userGroupId } = authResults;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`, {
        params: { userGroupId, isAdmin: true },
      });
      this.mapServices = results;
    },
    async getDepartmentsMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps`);
      this.departmentsMaps = results;
    },
    async getDepartmentMapServices() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_map_services`);
      this.departmentMapServices = results;
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getLayersInMap() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/inmap`, {
        params: {
          map_id: this.selectedMap.map_id,
        },
      });
      this.layersInMap = results;
    },
    departmentHasAccessToMap(departmentId) {
      return Boolean(
        this.newDepartmentsThatHaveAccessToMap.find(
          (d) => d.department_id === departmentId
        )
      );
    },
    async addMapToDepartmentMapService(departmentId) {
      const departmentMapService = {
        map_id: this.selectedMap.map_id,
        department_id: departmentId,
      };
      this.newDepartmentsThatHaveAccessToMap.push(departmentMapService);
      this.showUpdateAllLayerDepartmentAccessDialog = false;
    },
    async onUpdateAllLayerDepartments(departmentId) {
      const mapServicesThatDontHaveAllDepartmentMapHas =
        this.getMapServicesThatDontHaveAllDepartmentMapHas(
          this.selectedMap.map_id,
          departmentId
        );
      const mapServiceIds = mapServicesThatDontHaveAllDepartmentMapHas.map(
        (ms) => ms.map_service_id
      );
      const departmentIdsInMap = this.departmentsMaps
        .filter((dm) => dm.map_id === this.selectedMap.map_id)
        .map((dm) => dm.department_id);
      const departmentMapServicesToInsert = mapServiceIds.flatMap(
        (mapServiceId) => {
          return departmentIdsInMap.map((departmentId) => {
            return {
              map_service_id: mapServiceId,
              department_id: departmentId,
            };
          });
        }
      );
      await axios.post(`${APIURL}/department_map_services/batch`, {
        department_map_services: departmentMapServicesToInsert,
      });
      await this.getDepartmentsMaps();
      await this.getDepartmentMapServices();
      this.addMapToDepartmentMapService(departmentId);
      this.showUpdateAllLayerDepartmentAccessDialog = false;
    },
    toggleDepartmentAccessToMap(departmentId) {
      this.departmentIdBeingToggled = departmentId;
      const index = this.newDepartmentsThatHaveAccessToMap.findIndex(
        (d) => d.department_id === departmentId
      );
      if (index >= 0) {
        this.newDepartmentsThatHaveAccessToMap.splice(index, 1);
      } else {
        const mapServicesThatDontHaveAllDepartmentMapHas =
          this.getMapServicesThatDontHaveAllDepartmentMapHas(
            this.selectedMap.map_id,
            departmentId
          );
        if (
          this.layersInMap.length > 0 &&
          mapServicesThatDontHaveAllDepartmentMapHas.length > 0
        ) {
          this.showUpdateAllLayerDepartmentAccessDialog = true;
          return;
        }
        this.addMapToDepartmentMapService(departmentId);
      }
    },
    toggleAllDepartmentsAccessToMap() {
      if (this.newDepartmentsThatHaveAccessToMap.length === 0) {
        this.newDepartmentsThatHaveAccessToMap = this.departments.map((d) => {
          const { department_id: departmentId } = d;
          return {
            map_id: this.selectedMap.map_id,
            department_id: departmentId,
          };
        });
      } else {
        this.newDepartmentsThatHaveAccessToMap = [];
      }
    },
  },
  beforeMount() {
    this.getDepartments();
    this.getMapServices();
    this.getDepartmentsMaps();
    this.getDepartmentMapServices();
    this.getLayersInMap();
    this.getTableHeight();
  },
  beforeDestroy() {
    this.$options.resizeObserver?.disconnect?.();
    this.$options.mutationObserver?.disconnect?.();
  },
  watch: {
    departmentsThatHaveAccessToMap: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.newDepartmentsThatHaveAccessToMap = cloneDeep(val);
        }
      },
    },
  },
};
</script>
